.close-button {
    margin-left: auto;
    background-color: #f0f0f0; /* Light gray background */
    border: none; /* Remove default border */
    border-radius: 8px; /* Rounded corners */
    width: 26px; /* Fixed width */
    height: 26px; /* Fixed height */
    font-size: 16px; /* Size of the "X" */
    font-weight: bold; /* Bold "X" */
    color: #333; /* Dark color for the "X" */
    cursor: pointer; /* Pointer cursor on hover */
    display: flex; /* Center the content */
    align-items: center; /* Vertically center */
    justify-content: center; /* Horizontally center */
    padding: 0; /* Remove padding */
    outline: none; /* Remove outline on focus */
    transition: background-color 0.2s ease-in-out; /* Smooth background color transition */
  }
  
  .close-button:hover {
    background-color: #e0e0e0; /* Slightly darker gray on hover */
  }
  
  .close-button:active {
    background-color: #d0d0d0; /* Even darker gray on click */
  }

.betCard {
    display: flex;
    max-height: 400px;
    max-width: 400px;
    height: 170px;
    width: 400px;
    /* color: white; */
    border-radius: 8px;
    background-color: white;
    padding: 10px 8px;
    flex-direction: column;
}

.betCard-header {
    display: flex;
    gap: 10px;
    align-items: center;
}

.betCard-header-img {
    width: 60px;
    height: 60px;
    object-fit: cover;
    border-radius: 4px;
}

.betCard-header-text {
    color:black;
    font-family: 'Helvetica';  
    font-weight: 600;
    font-size: 20px;
    margin: 0;
}

.betCard-button-yes {
    flex: 1;
    font-family: 'Helvetica';
    background-color: rgba(39, 174, 96, 0.1);
    border: none;
    border-radius: 4px;
    transition: background-color 0.3s ease;
    cursor: pointer;
}

.betCard-button-yes > p {
    color: rgba(39, 174, 96, 1.0);
    font-size: 16px;
}

.betCard-button-yes:hover {
    background-color: rgba(39, 174, 96, 1.0);
}

.betCard-button-yes:hover p{
    color: white;
}

.betCard-button-no {
    flex: 1;
    font-family: 'Helvetica';
    border: none;
    border-radius: 4px;
    background-color: rgba(230,72,0, 0.1);
    cursor: pointer;
}

.betCard-button-no > p {
    color: rgba(230,72,0, 1.0);
    font-size: 16px;
}

.betCard-button-no:hover {
    background-color: rgba(230,72,0, 1.0);
}

.betCard-button-no:hover p{
    color: white;
}

.betCard-header-percent {

}

.betCard-action {
    display: flex;
    flex-direction: row;
    gap: 12px;
    margin-top: 38px;
}

.betCard-stats {
    display: flex;
    gap: 10px;
    font-family: 'Helvetica';
    color: #888;
    font-size: 14px;
    margin-top: 10px;
}

.betCard-value-input {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px 0;
  }
  
  .betCard-input-container {
    display: flex;
    align-items: center;
  }
  
  .betCard-input {
    width: 140px;
    padding: 7px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
    text-align: center;
  }
  
  .betCard-buttons {
    display: flex;
    gap: 5px;
    margin-left: 10px;
  }
  
  .betCard-buttons button {
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    padding: 7px 10px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    font-family: 'Helvetica';
    color: #888;
  }
  
  .betCard-buttons button:hover {
    background-color: #dcdcdc;
  }
  
  .betCard-slider {
    width: 100px;
    margin-left: 20px;
    accent-color: #888; /* Color of the slider */
    height: 6px;
  }

.betCard-button {
    flex: 1;
    font-family: 'Helvetica';
    /* background-color: rgba(39, 174, 96, 0.1); */
    border: none;
    border-radius: 4px;
    height: 60px;
    /* transition: background-color 0.3s ease; */
    cursor: pointer;
}

.yes {
    background-color: rgba(39, 174, 96, 1.0);
    /* color: white' */
}

.no {
    background-color: rgba(230,72,0, 1.0);
}

.no > p {
    color: white;
    font-size: 16px;
    font-weight: 600;
    margin: 0;
}

.yes > p {
    color: white;
    font-size: 16px;
    font-weight: 600;
    margin: 0;
}

.yes > span {
    margin-top: 4px;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.7);
}

.no > span {
    margin-top: 4px;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.7);
}