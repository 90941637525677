.progress-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
  }
  
  .progress-text {
    font-size: 18px;
    font-weight: bold;
    font-family: 'Helvetica';
    color: #333;
    margin-top: 0px; /* Adjust to position text in the center of the half-circle */
    position: absolute;
  }
  
  .label {
    margin-top: -10px;
    font-size: 16px;
    color: #888;
    font-family: 'Helvetica';
  }
  